import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function GstRegistrationCancellation() {
  /* Slider */
  // const cancelSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Cancellation of GST Registration`,
  //     content: `India's  GST Registration Cancellation Service`,
  //     image: "/imgs/registration/msme/msme-doc.png",
  //     alt_tag: "Best Online Cancellation of GST Registration Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online GST Registration Cancellation`,
  //     content: `Best GST Cancellation Service in Hosur`,
  //     image: "/imgs/registration/msme/msme-slide.png",
  //     alt_tag: "Best Online Cancellation of GST Registration in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online GST Cancellation Service`,
  //     content: `Best  of GST Cancellation Platform`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag: "Best Cancellation of GST Registration in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `GST Registration Cancellation`,
    buyBtnLink: `#pricing-buy`,
    price: `999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const cancelAboutData = {
    header: `GST Registration Cancellation`,
    sub_title: `For Personal and Business Related GST Registration Cancellation Service in Hosur.`,
    content_paragraph: [
      // `On July 1, 2017, the Goods and Service Tax, generally known as GST, was introduced in order to improve
      // company accessibility and give individuals greater tax advantages. The mechanism is automated by the
      // government's new indirect tax policy, which reduces manual intervention and speeds up the process.`,

      `Taxpayer who has had their GST registration cancelled is no longer a GST-registered individual. He is 
      not required to file GST reports, pay or collect GST, or claim the input tax credit.As a result, GST has 
      many benefits, one of which is the immediate deletion of the GST Registration number. 
      Let's examine the need for and procedure for the GST cancellation process.`,
    ],
  };

  /* Scroll Nav Data */
  const cancelScrollId = [
    {
      id: `#grc-procedure`,
      heading: `Procedure`,
    },
    {
      id: `#Cancelling`,
      heading: `Cancelling a GST`,
    },
    {
      id: `#Steps-grc`,
      heading: `Steps`,
    },
    {
      id: `#document`,
      heading: `Documents Cancellation`,
    },
  ];

  /* Img Content Component Data */
  const cancelIcData = {
    id: 'grc-procedure',
    mt_div: 'mt-5',
    mt_img: '',
    header: `GST Registration Cancelation Procedure`,
    image: '/imgs/business/din.png',
    alt_tag: 'GST Registration Cancellation Registration Procedure',

    points: [
      {
        content: `An individual will receive a show-cause notice in the form GST REG-17 if the proper officer decides to cancel their registration for any reason.`,
        icon: true,
      },
      {
        content: `The person must react in form REG-18 within seven days of receiving the notice, outlining the reasons his registration shouldn't be cancelled.`,
        icon: true,
      },
      {
        content: `The appropriate authority will dismiss the matter and issue an order GST REG-20 if the response is acceptable.`,
        icon: true,
      },
      {
        content: `If the registration is eligible for cancellation, the appropriate authority will issue an order in GST REG-19.`,
        icon: true,
      },
      {
        content: `Within 30 days after receiving the show cause response, the order will be delivered. `,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const cancelCfData = {
    id: 'Cancelling',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'GST Registration Cancellation Reasons',
    content: `For the following circumstances, your registration may be cancelled:`,
    body: [
      {
        points: `Person who is already registered with the government but is exempt from 
        registration under the aforementioned GST Act.`,
        icon: true,
      },
      {
        points: `Either the company closes, is completely transferred for any cause, such as the owner's 
        passing, merged with another legal entity, demerged, or is otherwise disposed of.`,
        icon: true,
      },
      {
        points: `The taxpayer (apart from someone who voluntarily registered 
          under subsection (3) of section 25 of the GST Act).`,
        icon: true,
      },
      {
        points: `The taxable person, excluding someone who voluntarily registered.`,
        icon: true,
      },
      {
        points: `Registered person has transgressed the rules established by the Act or its provisions.`,
        icon: true,
      },
      {
        points: `Anyone registered who, aside from a person paying the composition 
        levy, failed to submit returns three times in a row.`,
        icon: true,
      },
      {
        head: `Consequences of terminating your GST registration`,
        points: `If the GST number is removed, the taxpayer will not be required to 
        pay GST, and you won't be able to make a GST credit claim. For certain types 
        of enterprises, GST registration is necessary.`,
        icon: true,
      },
      {
        points: `If they cancel their GST registration and keep running their business, 
        they will be charged with an offence and fined.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const cancelCiData = {
    id: 'document',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Cancellation Of Documents',
    paragraph: `For those who are unable to use the above process, GST REG 16 must be used to submit a 
    cancellation request. The legal heirs of a decedent taxpayer will follow the steps outlined below.`,
    points: [
      {
        content: `Request for cancellation must be submitted using Form GST REG 16.`,
        icon: true,
      },
      {
        content: ` LLP Or Limited Liability Partnership`,
        icon: true,
      },
      {
        content: `Responsibility for LLP.`,
        icon: true,
      },
      {
        content: `Payment details.`,
        icon: true,
      },
      {
        content: `Form GST REG 16 must include information on the inputs, semi-finished goods, and finished
         goods that were in stock on the day the registration cancellation request was made.`,
        icon: true,
      },
      {
        content: `Within 30 days of receiving the application, the appropriate authority must issue a
         cancellation order under GST REG-19.`,
        icon: true,
      },
      {
        content: `On a date determined by the officer, the cancellation will go into effect, and the taxable 
        person will be informed.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/msme.png',
    alt_tag: 'Required Documents for MsMe Registration',
  };

  /* Slider */
  const imgSlider = [
    '/imgs/assited/credit.png',
    '/imgs/assited/professional-accounting.png',
    '/imgs/assited/management.png',
  ];

  /*  Slider Content Component Data */
  const cancelSCData = {
    id: 'Steps-grc',
    background: [],
    mt_div: 'mt-3',
    header:
      'Here Are The Steps For Migrating Taxpayers To Cancelling Their GSTIN On The GST Portal:',
    content: [
      {
        points: `Cancellation page appears.`,
        icon: true,
      },
      {
        points: `Your GSTIN and company name will be shown right away.`,
        icon: true,
      },
      {
        points: `You must state the rationale behind your cancellation.`,
        icon: true,
      },

      {
        points: `Note: Taxpayers who haven't sent out tax invoices yet can use this service. 
        A taxpayer must complete FORM GST REG-16 when issuing a tax invoice. Check out the list below.`,
        icon: false,
      },
    ],

    images: imgSlider,
  };

  /* FaQ data */
  var GSTRegCancelFAQ = [
    {
      header: 'Can you cancel your GST registration?',
      body: [
        {
          content: `The granted GST registration may be cancelled for particular
          circumstances. The department may initiate the cancellation on its
          own, or the registered person may submit a request to have their
          registration cancelled. If the registered person passes away, the
          legal heirs may file a petition for cancellation.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'After 180 days, how can I revoke my GST registration?',
      body: [
        {
          content: `The only way to revoke a GST registration after 180 days is to
          file an appeal with the local area's commissioner of GST.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is it possible to terminate my GST registration if my annual revenue
      is less than 20 lakhs?`,
      body: [
        {
          content: `If you accidentally registered for GST and want to revoke your
          registration, you can only do so if your company generates less than
          $20,000 in revenue annually. (Total of sale and unauthorized
          acquisition).`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `How long do you have to cancel your GST registration?`,
      body: [
        {
          content: `Let's say the tax official is happy with your justifications. If so,
          within 30 days of receiving your response in Form GST REG-24, he
          shall issue an order revoking your GST registration in Form GST
          REG-22. The tax officer may reject the application by issuing an
          order in Form GST REG-05 if he is unhappy with the reasons given.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is the cancellation of Reg. affect the liability?`,
      body: [
        {
          content: `Subsection 3 No, The cancellation of registration under this section shall not affect the 
          liability of the person to pay tax and other dues under this Act or to discharge any obligation under
           this Act or the rules made thereunder- for any period prior to the date of cancellation whether or not
            such tax and other dues are determined before or after the date of cancellation.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `How do I terminate my GST registration that has been suspended?`,
      body: [
        {
          content: `To use the GST Portal, go to www.gst.gov.in. To access the
          account, type the right username and password. In the GST
          Dashboard, click on Services, then Registration, then Application
          for Revocation of Cancelled Registration.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is the cancellation deemed to be affect in all act of GST?`,
      body: [
        {
          content: `Subsection 4 Yes, The cancellation of registration under the State Goods and Services 
          Tax Act or the Union Territory Goods and Services Tax Act, as the case may be, shall be deemed to be 
          a cancellation of registration under this Act.`,
          icon: false,
        },
      ],
    },
    {
      header: `What will be the manner of calculation of amount payable  u/s/s  5 ?`,
      body: [
        {
          content: `Subsection 6  The amount payable under sub-section (5) shall be calculated in such manner as may be prescribed.`,
          icon: false,
        },
      ],
    },
    {
      header: `If a business concern has demerged, is cancellation of registration of GST possible?`,
      body: [
        {
          content: `Yes, demerged business concern may cancel GST registration by applying with concerned authorities.`,
          icon: false,
        },
      ],
    },
    {
      header: `GST registration has been obtained by means of fraud, wilful misstatement or suppression of facts. How is such registration of GST treated?`,
      body: [
        {
          content: `Where any registration has been obtained by means of fraud, wilful misstatement or suppression of facts, the proper officer may cancel
           the GST registration with retrospective effect, subject to the provisions of section 29.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Gst Registration Cancellation'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={cancelSlider} /> */}

          <Package packageData={PackageCompData} />
          <AboutWithForm about_content={cancelAboutData} />
          <Counter />

          <ScrollNav scroll_data={cancelScrollId} />
          <ImgContent item={cancelIcData} />
          <ContentForm CFSection_data={cancelCfData} />
          <SliderContent ScSection_data={cancelSCData} />
          <ContentImg CISection_data={cancelCiData} />
          <Cta />

          <Guidance />

          <FAQAccord items={GSTRegCancelFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
